
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.ampifire-login::v-deep {
    background-color: $blueish !important;
    border-top-style: solid !important;
    border-top-width: 0.25em !important;
    border-top-color: $primary-color !important;
    width: 500px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;

    .v-text-field--filled .v-input__slot {
        background-color: $white !important;

        input {
            filter: none;
            color: $secondary-color;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-internal-autofill-previewed,
    input:-internal-autofill-selected {
        filter: none;
        border: none;
        -webkit-text-fill-color: $secondary-color;
        -webkit-box-shadow: 0 0 0 100px white inset !important;
        transition: inherit;
        background-color: transparent !important;
    }
}

.fade-in-enter-active {
    transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
    transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
    opacity: 0;
}
